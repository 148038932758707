<template>
    <div class="board-container">
        <div role="table" class="board-table board-table--input">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader">상품 선택</div>
                    <div role="cell">
                        <div class="form-input">
                            <select class="select select--wide" name="">
                                <option value="" selected disabled>문의하실 상품을 선택하세요.</option>
                                <option value="">밸리스 만능츄르 대용량 15g*20ea(300g) 밸리스 만능츄르 대용량 15g*20ea(300g) 밸리스 만능츄르 대용량</option>
                                <option value="">[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div role="row">
                    <div role="columnheader">이메일</div>
                    <div role="cell">
                        <div class="form-input">
                            <input class="input" type="text">
                        </div>
                    </div>
                </div>
                <div role="row">
                    <div role="columnheader">제목</div>
                    <div role="cell">
                        <div class="form-input">
                            <select class="select" name="" id="">
                                <option value="">카테고리</option>
                            </select>
                            <input class="input" type="text">
                        </div>
                    </div>
                </div>
                <div role="row">
                    <div role="cell" class="full">
                        <div style="height:300px;border:1px solid #ddd">
                            <div>고객이 질문한 내용입니다.</div>
                            <div>주문한지 3일됐는데 언제쯤 배송되나요? 아직 도착하지 않았습니다. 언제쯤 배송이 완료될까요??</div>
                            <div>주문한지 3일됐는데 언제쯤 배송되나요? 아직 도착하지 않았습니다.</div>
                            <div>언제쯤 배송이 완료될까요??</div>
                        </div>
                    </div>
                </div>
                <div role="row">
                    <div role="cell" class="full">
                        <div style="height:300px;border:1px solid #ddd">
                            <div>에디터 영역</div>
                            <div>안녕하세요. 고객님.</div>
                        </div>
                    </div>
                </div>
                <div role="row" v-for="(item, index) in files">
                    <div v-if="index == 0" role="columnheader">썸네일 이미지</div>
                    <div v-else role="columnheader">첨부파일 {{index}}</div>
                    <div role="cell">
                        <div class="file-wrap">
                            <label class="file" :for="'upfile' + index">
                                <input :id="'upfile' + index" class="input" type="file" @change="fileChange(index)">
                                <span class="text"><span>{{item.name}}</span></span>
                                <span class="button button--primary">파일선택</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="board-buttons board-buttons--right-center">
            <!-- <div class="left">
                <a class="button button--border" href=""><span>수정</span></a>
                <a class="button button--border" href=""><span>삭제</span></a>
                <a class="button button--border" href=""><span>답글</span></a>
            </div> -->
            <div class="right">
                <a class="button button--border" href=""><span>취소</span></a>
                <a class="button button--primary" href=""><span>확인</span></a>
            </div>
        </div>
    </div>
</template>

<script>
export default{
	components: {
	},
    data: function() {
        return{
        }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
